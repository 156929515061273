













import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectContactInfo from "../components/project-detail/ProjectContactInfo.vue";
import ProjectBusinessInfo from "../components/project-detail/ProjectBusinessInfo.vue";
import Project from "@/entity/Project";
import ProjectStatusBadge from "@/components/detail-page/ProjectStatusBadge.vue";
import DetailLayoutWrapper from "@/components/detail-page/DetailLayoutWrapper.vue";
import User from "@/entity/User";
import UserService from "@/services/UserService";

@Component({
  components: {
    DetailLayoutWrapper,
    ProjectContactInfo,
    ProjectBusinessInfo,
    ProjectStatusBadge,
  },
})
export default class ProjectDetailView extends Vue {
  @Prop({ required: true }) project!: Project;

  isFetchingData = false;
  users: User[] = [];

  async fetchData(): Promise<void> {
    try {
      this.isFetchingData = true;
      this.users = await UserService.findAll();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isFetchingData = false;
    }
  }

  created() {
    this.fetchData();
  }
}
