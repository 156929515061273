



































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Project, { ProjectType } from "@/entity/Project";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import ItemList from "@/components/detail-page/ItemList.vue";
import User from "@/entity/User";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import { formatTimestamp } from "@/utils/date";
import BigNumber from "@/components/detail-page/BigNumber.vue";

@Component({
  components: {
    SectionTitle,
    ItemList,
    BigNumber,
  },
})
export default class BusinessInfo extends Vue {
  @Prop() project!: Project;
  @Prop({ default: () => [] }) users!: User[];

  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];

  ProjectType = ProjectType;

  formatTimestamp(value: number | null): string {
    return value === null ? "" : formatTimestamp(value);
  }

  get consultantNames(): string[] {
    const names: string[] = [];

    const first = this.users.find(
      (user) => user.id === this.project.consultantId
    );
    const second = this.users.find(
      (user) => user.id === this.project.consultantSecondId
    );

    if (first) {
      names.push(first.displayName);
    }

    if (second) {
      names.push(second.displayName);
    }

    return names;
  }

  get regionName(): string | null {
    const region = this.regions.find(
      (region) => region.id === this.project.projectContact.regionId
    );
    return region ? region.name : null;
  }
}
