







































































import { Component, Prop, Vue } from "vue-property-decorator";
import Project, { ProjectType } from "@/entity/Project";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import SectionHeading from "@/components/detail-page/SectionHeading.vue";
import ContactList from "@/components/detail-page/ContactList.vue";
import Address from "@/components/detail-page/Address.vue";
import OwnerName from "@/components/detail-page/OwnerName.vue";
import ProjectStatusBadge from "@/components/detail-page/ProjectStatusBadge.vue";
import ItemList from "@/components/detail-page/ItemList.vue";
import companyDetailNavigationTo from "@/utils/companyDetailNavigateTo";

@Component({
  components: {
    SectionHeading,
    SectionTitle,
    ContactList,
    Address,
    OwnerName,
    ProjectStatusBadge,
    ItemList,
  },
})
export default class ProjectContactInfo extends Vue {
  @Prop() project!: Project;

  ProjectType = ProjectType;

  get to() {
    return companyDetailNavigationTo(this.project.projectContact);
  }

  get ownerLabel(): string {
    if (this.project.projectType === ProjectType.transaction) {
      return this.$tc("owner", 1).toString();
    } else {
      return this.$t("client").toString();
    }
  }
}
