






























import { Vue, Component, Prop } from "vue-property-decorator";
import Contact from "@/entity/Contact";
import contactDetailNavigationTo from "@/utils/contactDetailNavigateTo";

@Component
export default class OwnerName extends Vue {
  @Prop({ default: null }) owner!: Contact | null;

  get fullName(): string {
    if (this.owner) {
      return `${this.ownersSalutation || ""} ${this.owner.firstName || ""} ${
        this.owner.lastName || ""
      } ${this.owner.title || ""}`.trim();
    }
    return "N/A";
  }

  get ownersSalutation(): string | null {
    if (!this.owner) {
      return null;
    }

    if (this.$te(`salutationNames.${(this.owner as Contact).salutationForm}`)) {
      return this.$tc(
        `salutationNames.${(this.owner as Contact).salutationForm}`
      );
    }

    return null;
  }

  get isEmpty(): boolean {
    return this.owner === null;
  }

  get to() {
    if (this.owner === null) {
      return null;
    }
    return contactDetailNavigationTo(this.owner);
  }
}
