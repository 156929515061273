









import { Vue, Component, Prop } from "vue-property-decorator";
import { projectStatusColor } from "@/entity/Project";

@Component
export default class ProjectStatusBadge extends Vue {
  @Prop({ required: true, type: String }) activeStatus!: string;

  statusColor = projectStatusColor;
}
